<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <div v-else>
    <section>
      <b-card v-if="userInfoFromQuery">
        <b-media>
          <template #aside>
            <b-avatar
              variant="primary"
              :text="userInitials"
              :src="userProfileImage"
              size="7rem"
            />
          </template>

          <h6>
            <b-badge
              variant="primary"
              class="font-weight-normal"
              style="margin-right: 2px"
            >
              {{
                userInfoFromQuery.role === userRoles.MENTOR
                  ? mentorDisplay
                  : menteeDisplay
              }}
            </b-badge>
          </h6>

          <h4 class="mt-1 font-weight-500">
            {{ userInfoFromQuery.name }}
          </h4>
          <b-row class="ml-0 mt-1">
            <p class="mr-1">
              <feather-icon
                icon="MailIcon"
                size="16"
              />
              {{ userInfoFromQuery.mail }}
            </p>
            <p class="mr-1">
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
              Phone: {{ userInfoFromQuery.phone }}
            </p>
          </b-row>
        </b-media>
      </b-card>
      <div class="as-wrapper">
        <b-card
          v-for="(question, index) in displayQuestions"
          :key="question.id"
        >
          <template v-if="question.type_id === modelQuestionTypes.TEXT">
            <aom-application-single-text-box
              :question.sync="question"
              :index="index"
              read-only
              :allow-edit="isLoggedUsersApplication"
              @edit="goToApplicationEdit"
            />
          </template>
          <template v-if="question.type_id === modelQuestionTypes.CHOICE">
            <aom-application-choice
              :question.sync="question"
              :index="index"
              read-only
              :allow-edit="isLoggedUsersApplication"
              @edit="goToApplicationEdit"
            />
          </template>
          <template v-if="question.type_id === modelQuestionTypes.MATCHING">
            <aom-application-multi-choice-matching
              :question.sync="question"
              :index="index"
              read-only
              :allow-edit="isLoggedUsersApplication"
              @edit="goToApplicationEdit"
            />
          </template>
          <template v-if="question.type_id === modelQuestionTypes.SCALE">
            <aom-application-rating
              :question.sync="question"
              :index="index"
              read-only
              :allow-edit="isLoggedUsersApplication"
              @edit="goToApplicationEdit"
            />
          </template>
        </b-card>
      </div>
    </section>
  </div>
</template>

<script>
import { BBadge, BCard, BMedia, BAvatar, BRow } from "bootstrap-vue";
import { questionTypes as modelQuestionTypes } from "@/models/questionTypes";
import { makeErrorToast } from "@/libs/utils";
import { userRoles } from "@/models/userRoles";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import AomApplicationSingleTextBox from "@aom-core/AomApplicationSingleTextBox.vue";
import AomApplicationChoice from "@aom-core/AomApplicationChoice.vue";
import AomApplicationMultiChoiceMatching from "@aom-core/AomApplicationMultiChoiceMatching.vue";
import AomApplicationRating from "@aom-core/AomApplicationRating.vue";

export default {
  name: "ApplicationOverview",
  components: {
    AomSkeletonLoader,
    BBadge,
    AomApplicationSingleTextBox,
    AomApplicationChoice,
    AomApplicationMultiChoiceMatching,
    AomApplicationRating,
    BCard,
    BMedia,
    BAvatar,
    BRow,
  },

  data() {
    return {
      userRoles,
      modelQuestionTypes: modelQuestionTypes,
      isLoading: false,
      ownApplication: null,
    };
  },

  computed: {
    userInitials() {
      const fullName = this.userInfoFromQuery.name;
      const nameParts = fullName.split(" ");
      const lastName = nameParts[nameParts.length - 1];
      const lastInt = lastName.charAt(0);
      const firstInt = fullName.charAt(0);
      return `${firstInt}${lastInt}`;
    },
    userProfileImage() {
      return this.userInfoFromQuery?.profile_image?.url || "";
    },
    appCurrentProgram() {
      return this.$store.getters["app/currentProgram"];
    },
    applicationSetId() {
      const program = this.$store.getters["participants/program"];
      if (program && program.application_set) {
        return program.application_set.id;
      }
      return null;
    },
    profile() {
      return this.$store.getters["profile/profile"];
    },
    isParticipantPortal() {
      return this.$store.getters["app/isParticipantPortal"];
    },
    answerData() {
      return this.$store.getters["participants/answerData"];
    },
    displayQuestions() {
      if (this.answerData && this.answerData.questions) {
        return this.answerData.questions.filter(
          q => q.type_id !== modelQuestionTypes.SCALE || !q.parent_id
        );
      }
      return [];
    },
    mentorDisplay() {
      return this.$store.getters["participants/mentorDisplay"];
    },
    menteeDisplay() {
      return this.$store.getters["participants/menteeDisplay"];
    },
    roleFromRoute() {
      const routeSplit = this.$route.path.split("/");
      return Number(routeSplit[routeSplit.length - 2]);
    },
    currentRoleDisplay() {
      if (this.isParticipantPortal) {
        if (Number(this.roleFromRoute) === Number(userRoles.MENTOR)) {
          return this.mentorDisplay;
        }

        if (Number(this.roleFromRoute) === Number(userRoles.MENTEE)) {
          return this.menteeDisplay;
        }
      }
      return "";
    },
    pageHeader() {
      if (this.currentRoleDisplay) {
        return `${this.currentRoleDisplay} Application`;
      }
      return "";
    },
    userIdFromQuery() {
      return this.$route.query.userId ?? 0;
    },
    isEditable() {
      return !this.userIdFromQuery;
    },
    userInfoFromQuery() {
      if (this.userIdFromQuery) {
        return JSON.parse(localStorage.getItem("userInfoApplication"));
      }
      return null;
    },
    isLoggedUsersApplication() {
      if (this.userIdFromQuery) {
        return Number(this.userIdFromQuery) === Number(this.profile.id);
      }
      return true;
    },
  },

  watch: {
    roleFromRoute() {
      this.loadApplication();
    },

    pageHeader() {
      this.$store.commit("app/SET_CUSTOM_PAGE_TITLE", this.pageHeader);
    },
  },

  created() {
    this.loadApplication();
    this.$store.commit("app/SET_CUSTOM_PAGE_TITLE", this.pageHeader);
  },

  beforeDestroy() {
    this.$store.commit("app/SET_CUSTOM_PAGE_TITLE", null);
  },

  mounted() {
    const breadcrumb = window.document.querySelector(".breadcrumbs-top");
    if (breadcrumb) {
      const breadcrumbItems = breadcrumb.querySelectorAll(".breadcrumb-item");
      if (breadcrumbItems && breadcrumbItems.length > 0) {
        breadcrumbItems[breadcrumbItems.length - 1].innerHTML =
          this.currentRoleDisplay;
      }
    }
  },

  methods: {
    async loadApplication() {
      try {
        this.isLoading = true;

        if (
          this.isParticipantPortal &&
          this.appCurrentProgram &&
          this.appCurrentProgram.id &&
          this.applicationSetId
        ) {
          this.ownApplication = await this.$store.dispatch(
            "participants/FETCH_OWN_APPLICATION_SETS",
            {
              programId: this.appCurrentProgram.id,
              applicationSetId: this.applicationSetId,
              role: this.roleFromRoute,
            }
          );

          if (this.ownApplication) {
            await this.$store.dispatch(
              "participants/FETCH_OWN_APPLICATION_ANSWERS",
              {
                programId: this.appCurrentProgram.id,
                formId: this.ownApplication.id,
                userId: this.userIdFromQuery
                  ? this.userIdFromQuery
                  : this.profile.id,
              }
            );
          }
        }
      } catch (e) {
        console.log(e);
        if (e?.response) {
          const { status, data } = e.response;
          if (status === 404) {
            this.$toast(makeErrorToast(data.message));
            return;
          }
        }
        this.$toast(makeErrorToast("Load Application failed."));
      } finally {
        this.isLoading = false;
      }
    },

    goToApplicationEdit(question) {
      if (question && question.id) {
        this.$router.push({
          name: "participant-application-edit",
          params: {
            role: this.roleFromRoute,
            questionId: question.id,
          },
        });
      }
    },

    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName
      );
    },

    getAnswerChoices(qValue) {
      const answerUpdate = qValue.answers.map(answer => {
        const choice = qValue.choices.find(ch => ch.id === answer.choice_id);
        if (choice.is_other) {
          return { ...answer, is_other: true };
        } else {
          return {
            ...answer,
            answer_text: this.getTranslationTextByUserLocale(
              choice.translations,
              "choice_text"
            ),
          };
        }
      });
      return answerUpdate;
    },
    statementsAnsw(question) {
      return Boolean(
        question.statements.filter(s => s.answers.length > 0).length > 0
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.text-small {
  font-size: 14px;
}
.rating-scale-answer-row-content {
  font-size: 1em;
  text-transform: none;
}
</style>
